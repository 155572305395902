import gsap from 'gsap';
import { merge } from 'lodash-es';

export type AccordionOptions = {
  allowMultiple: boolean;
  defaultState: boolean;
  item: string;
  toggle: string;
  content: string;
};

const DEFAULT_OPTIONS: AccordionOptions = {
  allowMultiple: true,
  defaultState: true,
  item: '[data-accordion-item]',
  toggle: '[data-accordion-toggle]',
  content: '[data-accordion-content]'
};

class AccordionItem {
  constructor(
    public root: Element,
    public toggles: Element[],
    public content: Element,
    public open: boolean
  ) {
    const attrState = root.getAttribute('data-accordion-open');
    if (attrState != undefined) this.open = attrState === 'true';
  }

  addEventListeners = () => {
    for (const toggle of this.toggles) {
      toggle.addEventListener('click', this.toggle);
    }
  }

  removeEventListeners = () => {
    for (const toggle of this.toggles) {
      toggle.removeEventListener('click', this.toggle);
    }
  }

  toggle = () => {
    this.open = !this.open;
    const minHeight = gsap.getProperty(this.content, 'minHeight') || 0;

    if (this.open) {
      // opening
      gsap.set(this.content, { height: 'auto' });
      gsap.from(this.content, { height: minHeight, ease: 'power2.inOut', clearProps: 'all', onComplete: this.updateStateAttribute });
    } else {
      // closing
      gsap.to(this.content, { height: minHeight, ease: 'power2.out', clearProps: 'all', onComplete: this.updateStateAttribute });

      // scroll up if we're past this item
      const box = this.root.getBoundingClientRect();
      if (box.top < 0) {
        const doc = document.documentElement;
        gsap.to(doc, { scrollTo: doc.scrollTop + box.top, ease: 'power2.out' });
      }
    }
  }

  updateStateAttribute = () => {
    this.root.setAttribute('data-accordion-open', this.open.toString());
  }
}

class Accordion {
  root: Element;
  items: AccordionItem[] = [];

  constructor(root: Element | string, userOpts?: Partial<AccordionOptions>) {
    // validate root
    let tmpRoot: Element | null = null;
    if (typeof root === 'object') tmpRoot = root;
    if (!tmpRoot && typeof root === 'string') tmpRoot = document.querySelector(root);
    if (!tmpRoot) tmpRoot = document.querySelector('[data-accordion]');
    if (!tmpRoot) throw new Error('Unable to instantiate Accordion: No root element found.');
    this.root = tmpRoot;

    // query item elements
    const opts: AccordionOptions = merge(DEFAULT_OPTIONS, userOpts);
    this.items = [];
    this.root.querySelectorAll(opts.item).forEach(item => {
      const toggles: Element[] = [];
      item.querySelectorAll(opts.toggle).forEach(el => toggles.push(el));
      const content = item.querySelector(opts.content) as HTMLElement;
      this.items.push(new AccordionItem(item, toggles, content, opts.defaultState));
    });
    if (this.items.length === 0) throw new Error('Unable to instantiate Accordion: No collapsible items found.');

    // start listening to toggle events
    this.start();
  }

  start = () => {
    this.root.setAttribute('data-accordion', '');

    for (const item of this.items) {
      item.root.setAttribute('data-accordion-item', '');
      item.root.setAttribute('data-accordion-open', item.open.toString());

      item.content.setAttribute('data-accordion-content', '');
      for (const toggle of item.toggles) toggle.setAttribute('data-accordion-toggle', '');

      item.addEventListeners();
    }
  }

  destroy = () => {
    for (const item of this.items) {
      item.removeEventListeners();
    }
  }
}

export default Accordion;
