import { publish } from 'javascripts/utils/events';
import { iframeResizer } from 'iframe-resizer';
import { clamp } from 'lodash-es';

import Accordion from 'javascripts/components/accordion';

// initialize autoresizing of iframes
iframeResizer({}, 'iframe.producer-info');

// setup details accordion
new Accordion('.sections', {
  item: 'section',
  toggle: '.section-heading, .section-toggle, .section-photo',
  content: '.section-content',
  defaultState: false
});

// handle Add to Cart actions
document.querySelectorAll('[data-buy-button]').forEach(el => {
  el.addEventListener('click', evt => {
    const button = (el as HTMLElement);
    const saleId = button.getAttribute('data-sale-id');

    if (!saleId) return;

    button.classList.add('loading');
    publish('CART_UPDATE_REQUESTED', {
      saleId: parseInt(saleId),
      onSuccess: () => {
        button.classList.remove('loading');

        const suppressSlideoutCart = button.getAttribute('data-suppress-slideout-cart') === 'true';
        if (!suppressSlideoutCart) publish('CART_OPEN_OVERLAY');
      }
    });

    evt.preventDefault();
    return false;
  });
});

// ---- handle floating featured photo behavior ------------------------------------------------------------------- //
const BASE_WIDTH = 320; // fixed dimension of square photo
const FLOATING_THUMBNAIL_VISIBLE_WIDTH = 1200; // starting width where the floating thumbnail becomes visible

const offerDetail = document.querySelector('.offer-detail') as HTMLElement;
const mastheadThumbnail = document.querySelector('.offer-masthead .featured-sale-thumbnail') as HTMLElement;
const saleThumbnail = document.querySelector('.buy-cluster .floating-sale-thumbnail') as HTMLElement;
const imageBackdrop = document.querySelector('.buy-cluster .floating-sale-thumbnail .image-backdrop') as HTMLElement;
const legacyPhoto = document.querySelector('.buy-cluster .floating-sale-thumbnail .legacy-photo') as HTMLElement;
const squarePhoto = imageBackdrop || legacyPhoto;
const bottleShot = document.querySelector('.buy-cluster .floating-sale-thumbnail .bottle-shot') as HTMLElement;

let height = 0;
let aspectRatio = 0;
let bgOpacity = 0;
const updateFloatingBottleShot = () => {
  if (!offerDetail || !saleThumbnail || !squarePhoto || window.innerWidth < FLOATING_THUMBNAIL_VISIBLE_WIDTH) return;

  const offset = offerDetail?.offsetTop;
  const newHeight = clamp(window.scrollY - offset + BASE_WIDTH, 0, BASE_WIDTH);
  const newAspectRatio = newHeight / BASE_WIDTH;
  const newBgOpacity = clamp(newAspectRatio, 0, 1);

  // only render if values changed
  if (height !== newHeight || aspectRatio !== newAspectRatio || bgOpacity !== newBgOpacity) {
    height = newHeight;
    aspectRatio = newAspectRatio;
    bgOpacity = newBgOpacity;

    window.requestAnimationFrame(() => {
      // adjust sale thumbnail margin
      if (aspectRatio < 0.5) saleThumbnail.classList.remove('padded');
      else saleThumbnail.classList.add('padded');

      // adjust sale thumbnail height
      saleThumbnail.style.height = `${aspectRatio > 0.5 ? height : 0}px`;

      // object transparency and visibility
      mastheadThumbnail.style.opacity = (1 - clamp(newAspectRatio * 2, 0, 1)).toString();

      if (aspectRatio > 0.5) saleThumbnail.classList.add('visible');
      else saleThumbnail.classList.remove('visible');

      if (squarePhoto) squarePhoto.style.opacity = bgOpacity.toString();

      if (bottleShot) {
        if (aspectRatio > 0.5) bottleShot.classList.add('visible');
        else bottleShot.classList.remove('visible');
      }
    });
  }
}

// update on scroll/resize
window.addEventListener('scroll', updateFloatingBottleShot);
window.addEventListener('resize', updateFloatingBottleShot);

// update on inner mutation
const observer = new MutationObserver(updateFloatingBottleShot);
observer.observe(saleThumbnail, {
  attributes: true,
  childList: true,
  subtree: true
});

// update on load
setTimeout(updateFloatingBottleShot, 0);
