import {
  subscribe as pubsubSubscribe,
  unsubscribe as pubsubUnsubscribe,
  publish as pubsubPublish
} from 'pubsub-js';

// ---- export and helper functions ----------------------------------------- //
/**
 * Subscribe to a global PubSub event.
 * @param topic GlobalEvent to subscribe to.
 * @param fn Callback to execute when specified GlobalEvent has been fired.
 * @returns Cancellation token for this subscription. Pass to `unsubscribe` to dispose of this subscription instance.
 */
export const subscribe = <T extends GlobalEvent>(topic: T, fn: (data: GlobalEventData<T>) => void) => {
  return pubsubSubscribe(topic, (_: T, data: GlobalEventData<T>) => fn(data));
}

/**
 * Unsubscribe a previous subscription.
 * @param token Unsubscribe token received from `subscribe()`.
 */
export const unsubscribe = (token: string) => {
  return pubsubUnsubscribe(token);
}

/**
 * Publish an event (with data) to all subscribers.
 * @param topic GlobalEvent to subscribe to.
 * @param data Event data to be published to all subscribers.
 * @returns Returns true if at least 1 subscriber was published to. Otherwise, false.
 */
export const publish = <T extends GlobalEvent>(topic: T, data?: GlobalEventData<T>) => {
  return pubsubPublish(topic, data);
}
